import React from "react"

const IndexPage = () => {
  React.useEffect(() => {
    if(window){
     window.location.replace('https://escola.educandariovilaboa.com.br/fundamental-ii');
    }
  }, [...(typeof window !== 'undefined' ? [window]:[])])
  return <div></div>
};

export default IndexPage